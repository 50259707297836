var apiUrl;
var loginUrl

if(process.env.NODE_ENV == 'development'){
  apiUrl = process.env.REACT_APP_DEV_URL
  loginUrl = process.env.REACT_APP_DEV_BASE_API_URL
}
else{
  apiUrl = process.env. REACT_APP_PROD_URL
  loginUrl = process.env.REACT_APP_PROD_BASE_API_URL

}
const API_URL = {
  url: apiUrl,
  account: {
    login: `${loginUrl}/user/logindronline`,
    add: `${loginUrl}/user/add`,
  }
}

export default API_URL
  
import React, {Component} from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import MyVideo from './components/video.component'
import Login from './components/login.component'



export default class Routes extends Component {


render(){
   
    return(
        <Switch>
            <Route path="/video/:id" component={MyVideo} />
            <Route path="/video" component={MyVideo} />
            <Route path="/" component={Login} />
            
        </Switch>
    )
   }
}

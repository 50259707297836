import axios from "axios";
import API_URL from '../constants/api-urls.constant'

class AuthService {
 
  login(username, password) {
    return axios
      .post(API_URL.account.login, {
        username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }
}

export default new AuthService();

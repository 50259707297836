import React, {Component} from 'react';
import {Form,Button} from 'react-bootstrap'
import { Redirect } from 'react-router-dom';
import history from '../history'
import AuthService from '../services/auth.service'

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password:'',
            status: 0,
            redirect: null
        };
    
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleInputChange(event) {
        const target = event.target;
        const value =  target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    
      }

      handleSubmit =(event) => {
            event.preventDefault();
            AuthService.login(this.state.username, this.state.password).then((data)=> {
               
                if(data == 'Username or password incorrect'){
                  alert(data)
                }
                else{
                  const {username, datecreated} = data[0]
                  let redirectUrl = (username == 'doctor') ? "/video/doctor" : "/video"
                  this.setState({
                    redirect: redirectUrl
                  }) 
                }
               
              
              },
              error => {
                const resMessage =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
      
                this.setState({
                  loading: false,
                  message: resMessage
                });
              }
            );
    }
    
    render(){
      if (this.state.redirect != null) {
            
        history.push(this.state.redirect)
        return <Redirect to={this.state.redirect} />
       }
        return (
          <>
             <div className="loginDesktop">
            <div className="container">
                <div className="login">
                  <div className="col-lg-12"><h2>Login</h2></div>
                    
                      <div className="col-lg-12">
                          <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId="formUserName">
                                <Form.Label>UserName</Form.Label>
                                <Form.Control type="text" value={this.state.username} name="username" placeholder="User Name" onChange={this.handleInputChange} required />
                            </Form.Group>
                            <Form.Group controlId="formPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" value={this.state.password} name="password" placeholder="Password" onChange={this.handleInputChange} required />
                            </Form.Group>
                            
                            <Button variant="primary" type="submit">
                                Login
                            </Button>
                          </Form>
                      </div>
                   
                 </div>
            </div>
            </div>
             <div className="loginMobile" style={{backgroundColor:"white", marginTop:"0px", padding:"20px"}}>
              <div><h5>DURIAN-CliniSys</h5></div>
             <form onSubmit={this.handleSubmit}>
                   
                 <label>UserName</label>
                 <input type="text" style={{width:"100%"}} value={this.state.username} name="username" placeholder="User Name" onChange={this.handleInputChange} required />
             
             
                 <label>Password</label>
                 <input type="password" style={{width:"100%", marginBottom:"20px"}} value={this.state.password} name="password" placeholder="Password" onChange={this.handleInputChange} required />
                 <Button variant="primary" type="submit">
                   Login
                 </Button>
               </form>
            </div>
            </>
            
        );
    }
  }
  

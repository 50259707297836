import React, {Component, createRef} from "react"
import { SpinnerComponent } from 'react-element-spinner';
import {Form,Button} from 'react-bootstrap'
import { w3cwebsocket as W3CWebSocket } from "websocket";
import API_URL from '../constants/api-urls.constant'

var connectedUser = null
var stream = null
var yourConnection = null
var callLogin = null
var callCheckDr = null
var callReconnect = null
var callCut = null
var connection = new W3CWebSocket(API_URL.url);

const constraints = { 
    audio: true,
    video: true 
 };

export default class MyVideo extends Component {
       
    video = null
    canvas = null
   
    constructor(props) {
        super(props);
    
        this.state = { 
          src: ' ',
          streaming: false,
          yvideo: null,
          tvideo:null,
          canvas: null,
          inputEl: null,
          username:null,
          theirusername:null,
          id: null,
          isDrOnline: false,
          isLoggin: false,
          onCall: false,
          isLoading: false,
          timeStartCall: 0
        };
       
    }

    // Alias for sending messages in JSON format
    send = (message) => {
        if(connectedUser) {
            message.name = connectedUser;
        }
        if(connection.readyState == 1){
            connection.send(JSON.stringify(message));
        }
        else{
            connection = new W3CWebSocket(API_URL.url)
        }
        
    }
    onLogin = (success) => {
        if(success === false) {
            alert("Login unsuccessful, please try a different name.");
        } else {
            // loginPage.style.display = "none";
            // callPage.style.display = "block";
            // Get the plumbing ready for a call
            this.startConnection();
        }
    }
    onOffer = (offer, name) => {
        connectedUser = name;
        try{
            yourConnection.setRemoteDescription(new
            RTCSessionDescription(offer));
            yourConnection.createAnswer((answer) => {
            let ans = answer.sdp.replace("m=audio 9 UDP/TLS/RTP/SAVPF 111 103 104 9 0 8 106 105 13 110 112 113 126","m=audio 9 UDP/TLS/RTP/SAVPF 103")
            //console.log(ans)
            yourConnection.setLocalDescription(answer);
            this.send({
                type: "answer",
                answer: answer
                });
                }, (error) =>{
                    alert("An error has occurred");
                });
       }
       catch(err){
          console.log(err)
       }
    }

    onAnswer = (answer) => {
        yourConnection.setRemoteDescription(new
        RTCSessionDescription(answer));
    }
    
    onCandidate = (candidate) => {
        yourConnection.addIceCandidate(new RTCIceCandidate(candidate));
    }
    onLeave = () => {
        connectedUser = null;
        this.state.tvideo.current.srcObject = null
        yourConnection.close();
        yourConnection.onicecandidate = null;
        yourConnection.onaddstream = null;
        this.setupPeerConnection(stream);
    }
    hasUserMedia = () => {
        return !!(navigator.getUserMedia || navigator.webkitGetUserMedia
        || navigator.mozGetUserMedia || navigator.msGetUserMedia);
        }

    hasRTCPeerConnection = () => {
            window.RTCPeerConnection = window.RTCPeerConnection ||
            window.webkitRTCPeerConnection || window.mozRTCPeerConnection;
            return !!window.RTCPeerConnection;
            }

     startConnection = () => {
        
        if(this.hasUserMedia()) {
            //var audioContext = new AudioContext();
            navigator.getUserMedia = navigator.getUserMedia ||
            navigator.webkitGetUserMedia || navigator.mozGetUserMedia ||
            navigator.msGetUserMedia;
            navigator.getUserMedia(constraints, (myStream) => {
            //var microphone = audioContext.createMediaStreamSource(myStream);
            //microphone.connect(audioContext.destination);
            stream = myStream;
            this.state.yvideo.current.srcObject = stream
            if(this.hasRTCPeerConnection()) {
                this.setupPeerConnection(stream);
            } else {
                alert("Sorry, your browser does not support WebRTC.");
            }
        }, (error) => {
            console.log(error);
        });
        } else {
            alert("Sorry, your browser does not support WebRTC.");
        }
    }
    setupPeerConnection = (stream) => {
        var configuration = {
            'iceServers': [
                {
                  'urls': 'stun:stun.l.google.com:19302'
                }
            ]
        };
        yourConnection = new RTCPeerConnection(configuration);
        // Setup stream listening
        yourConnection.addStream(stream);
        yourConnection.onaddstream = (e) => {
            window.clearInterval(callCut)
            this.setState({
                isLoading: false
            })
            this.state.tvideo.current.srcObject = e.stream
        };
        // Setup ice handling
        yourConnection.onicecandidate = (event) => {
        if (event.candidate) {
        this.send({
        type: "candidate",
        candidate: event.candidate
        });
        }
        };
    }

    startPeerConnection = (user) => {
        connectedUser = user;
        // Begin the offer
        if(yourConnection){
            yourConnection.createOffer((offer) => {    
            //console.log(offer)
            this.send({
            type: "offer",
            offer: offer
            });
            yourConnection.setLocalDescription(offer);
            }, (error) => {
                alert("An error has occurred.");
            });
        }
        else{
            var configuration = {
                    'iceServers': [
                    {
                      'urls': 'stun:stun.l.google.com:19302'
                    }
                ]
            };
            yourConnection = new RTCPeerConnection(configuration);
        }
    };

    componentDidMount = () => {
        let id =this.props.match.params.id
        let yvideo= createRef()
        let tvideo= createRef()
             
        this.setState({
            yvideo,
            tvideo,
            id
        })

        callLogin = setInterval( this.handleLogin, 1000)
        callCheckDr = setInterval(this.handleDrOnline, 2000)
               
        if(this.hasUserMedia()){
            //var audioContext = new AudioContext();
            navigator.getUserMedia = navigator.getUserMedia ||
            navigator.webkitGetUserMedia || navigator.mozGetUserMedia ||
            navigator.msGetUserMedia;
            navigator.getUserMedia(  {audio: true,
                video: true }
             , (stream) => {
                //var microphone = audioContext.createMediaStreamSource(stream);
                //microphone.connect(audioContext.destination);
                //microphone.volume = 0.9
                this.state.yvideo.current.srcObject = stream

            
                this.setState({
                    streaming: true
                })
            
                }, (err) => {
                    console.log("Raised an error when capturing:", err);
                });

            connection.onmessage = (message) => {
                console.log("Got message", message.data);
                var data = JSON.parse(message.data);
                switch(data.type) {
                    case "removeDoc":
                        this.onRemoveUserAndDoc();
                        break;
                    case "checkDrOnline":
                        this.onDrOnline(data.status);
                        break;
                    case "login":
                        this.onLogin(data.success);
                    break;
                case "offer":
                        this.onOffer(data.offer, data.name);
                    break;
                case "answer":
                        this.onAnswer(data.answer);
                    break;
                case "candidate":
                        this.onCandidate(data.candidate);
                    break;
                case "leave":
                        this.onLeave();
                    break;
                default:
                    break;
            }

            };
            
            connection.onopen = () => {

            }
            connection.onclose = () => {
                // Try to reconnect in 5 seconds
                callReconnect = setInterval(this.reconnect, 5000);
            };
        }
        else{
            alert("Sorry, your browser does not support WebRTC.");
        }

    } 
    reconnect = () => {
        if(!connection || connection.readyState == 3){
            connection = new W3CWebSocket(API_URL.url)
        }
        else if(connection.readyState == 1)
        {
            window.clearInterval(callReconnect)
        }
            
    }
  
    cutCall = () => {
        let now = new Date()
        if(now - this.state.timeStartCall >= 5000){
            window.clearInterval(callCut)
            this.setState({
                isLoading: false,
                onCall: false
            })
        }
    }
    hangUpButton = () => {
        this.setState({
            onCall: false
        })
        this.send({
        type: "leave"
        });
        this.onLeave();
    }

    close = () => {
        this.send({
            type: "removeDoc",
            name: "doctor"
        });            
    }

    handleUserName = (e) => {
        e.preventDefault()
        let name = e.target.value;
        this.setState({
            username: name
        })
    }
    handleTheirUserName = (e) => {
        e.preventDefault()
        let name = e.target.value;
        this.setState({
            theirusername: name
        })
    }
    onRemoveUserAndDoc = () => {
        this.setState({
            isDrOnline: false
        })
    }
    onDrOnline = (status) => {
        if(status){
            this.setState({
                isDrOnline: status
            })
        }

        if(this.state.isDrOnline){
            window.clearInterval(callCheckDr) 
        }        
       
    }
    handleDrOnline = () => {
        this.send({
            type: "checkDrOnline",
            name: "doctor"
            });
    
    }
    refresh = () => {
        window.location.href = "/video/doctor"
    }
    createGuid= () => {  
        function _p8(s) {  
           var p = (Math.random().toString(16)+"000000000").substr(2,8);  
           return s ? "-" + p.substr(0,4) + "-" + p.substr(4,4) : p ;  
        }  
        return _p8() + _p8(true) + _p8(true) + _p8();  
     }  

    handleLogin = (e) =>{
     
        this.setState({
            isLoggin: true
        })
        let id =this.props.match.params.id
        let guid = this.createGuid()
        if(id){
            guid = "doctor"
        }
        this.send({
                    type: "login",
                    name: guid
                  });

        if(this.state.isLoggin)
        {
            window.clearInterval(callLogin)
        }
    }
    
    handleCall = (e) =>{
        e.preventDefault()
        callCut = setInterval(this.cutCall, 5000)
        this.setState({
            onCall: true,
            isLoading:  true,
            timeStartCall: new Date()
        })
        this.startPeerConnection("doctor") 
    }

    handleCapture = (event) => {
   
        if (this.state.streaming) {
            this.state.canvas.current.width = this.state.video.current.clientWidth;
            this.state.canvas.current.height = this.state.video.current.clientHeight;
            // var context = this.state.canvas.current.getContext('2d');
            // context.drawImage(this.state.video.current, 0, 0);
        }else {
            alert("Sorry, your browser does not support getUserMedia.");
        }
   }

   render(){
    let phone = '/phone.gif'

    return (
        
        <>
        <SpinnerComponent loading={this.state.isLoading} position="global" />
        <div id="container">
        <video autoPlay ref={this.state.yvideo} id="yours" muted></video>
            <video autoPlay ref={this.state.tvideo} id="theirs"></video>
       
         <div className="loginDesktop">
            
               <br></br>
              
               <div className="row online-1">
                    <div className="col-lg-12"><h5>Status: Doctor is {this.state.isDrOnline ? 'online' : 'offline'}</h5></div>
               </div>
               
              
               <br></br>
               <div className="row online-1">
                    <div className="col-lg-3">
                        {
                           ((!this.state.id) && (this.state.isDrOnline )) && (
                                <Form onSubmit={this.handleCall}> 
                                    <Button variant="primary" type="submit" disabled={this.state.onCall}>
                                        Call
                                    </Button>
                                </Form>
                            )
                        }
                           
                    </div>
                    <div className="col-lg-5">
                    <Button id="hang-up" onClick={this.hangUpButton}>Hang Up</Button>
                    </div>
                    <div className="col-lg-2">
                    {
                        ((this.state.id) && (this.state.isDrOnline )) && (
                            <Button id="Close" onClick={this.close}>Logout</Button>
                        )
                    }
                    </div>
               </div>
        </div>
         <div className="loginMobile" style={{backgroundColor:"white", marginTop:"0px", padding:"20px"}}>
    
           
            <div className="row">
                <div className="col-12"><h5>Status: Doctor is {this.state.isDrOnline ? 'online' : 'offline'}</h5></div>
            </div>
            <br></br>
            <form>
    
               {
               
                    ((!this.state.id) && (this.state.isDrOnline )) && (
                        <Button id="call" onClick={this.handleCall} disabled={this.state.onCall}>Call</Button>
                            
                    )
                    
                }
               
                    &nbsp;&nbsp;
                    <Button id="hang-up" onClick={this.hangUpButton}>Hang Up</Button>
                     &nbsp;&nbsp;
                    {
                        ((this.state.id) && (this.state.isDrOnline )) && (
                            <Button id="Close" onClick={this.close}>Logout</Button>
                        )
                    }
                     
              
           </form>

          
           </div>
        </div>
        
    
       </>
        );
   
}
}
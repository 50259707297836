
import MyVideo from './components/video.component'
import {BrowserRouter as Router} from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css";
// import 'font-awesome/css/font-awesome.min.css';
import './App.css';
import Routes from './routes'
import history from "./history";

function App() {
  return (
      <Router history={history}>
        <div className="main">
           <Routes></Routes>
        </div>
      </Router>
  );
}

export default App;
